@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Bold'),
    url('../assets/fonts/Axiforma/AxiformaBold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma SemiBold'),
    url('../assets/fonts/Axiforma/AxiformaSemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Medium'),
    url('../assets/fonts/Axiforma/AxiformaMedium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Regular'),
    url('../assets/fonts/Axiforma/AxiformaRegular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Light'),
    url('../assets/fonts/Axiforma/AxiformaLight.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Thin'),
    url('../assets/fonts/Axiforma/AxiformaThin.otf') format('opentype');
  font-weight: 100;
}
